import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  MenuItem,
  Menu,
  FormControlLabel,
  Tooltip,
  Typography,
  ClickAwayListener,
  Checkbox,
  Box,
  InputLabel,
  Select
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import { withStyles } from '@material-ui/core/styles';
// Load images
import capLogo from '../../assets/images/logo.svg';

// Styles
import styles from './styles';

import { CLIENT_IDS_ALLOWED_FOR_CONTACTS, headerMenu } from '../../utils/constants';
import { checkAuthState, getCurrentUser, getPermissions } from '../../utils/auth';
import { storageGetData, storageSetData } from '../../utils/storage';
import { actionCreators } from '../../containers/Auth/ducks/reducer';
import { actionCreators as authActions } from '../../redux/auth/actions';
import { withI18NContext } from '../i18n/i18nWrapper';

const Auth = require('j-toker');
const PubSub = require('pubsub-js');

class Header extends Component {
  static propTypes = {
    classes: PropTypes.object,
    currentPath: PropTypes.string,
    // Auth action creator to reset values when logout.
    logout: PropTypes.func,
    changeLocale: PropTypes.func
  };

  state = {
    hasLogout: false,
    anchorEl: null,
    mobileMoreAnchorEl: null,
    showOperativoTooltip: true
  };

  componentDidMount() {
    const hideOperativoTooltip = storageGetData('hideOperativoTooltip', null);

    this.setState({ showOperativoTooltip: !hideOperativoTooltip });
  }

  handleLogout = () => {
    const { logout } = this.props;

    Auth.signOut();

    PubSub.subscribe('auth.signOut.success', () => {
      logout();
    });
  };

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  canViewAdmin = currentUser => !!['super_admin', 'admin', 'supervisor', 'facilitator'].find(type => currentUser.user_type === type);

  canViewOrders = currentUser => currentUser.user_type !== 'visializator';

  menuSelectedClass = path => {
    const { classes, currentPath } = this.props;

    return path === `/${currentPath.split('/')[1]}` ? ` ${classes.menu}__menu__item_selected` : '';
  };

  apiMenuName = name => {
    const { currentUser } = this.props;

    return name === 'Operativo' ? currentUser.client_project_alias || 'Proyectos' : name;
  };

  apiMenuNameKey = name => {
    const { currentUser } = this.props;
    const i18nKeys = {
      Operativo: 'menu.operative',
      Ejecutivo: 'menu.executive',
      Analítica: 'menu.analitics'
    };

    return name === 'Operativo' ? currentUser.client_project_alias || i18nKeys[name] : i18nKeys[name];
  };

  hideMenuBadge = name => name !== 'Operativo';

  dontShowTooltip = () => {
    storageSetData('hideOperativoTooltip', true);
  };

  renderTooltip = () => {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography color="secondary">Importante!</Typography>
        <Typography>
          Ahora el menu{' '}
          <Box component="span" fontWeight="fontWeightBold">
            Operativo
          </Box>{' '}
          ha cambiado a{' '}
          <Box component="span" fontWeight="fontWeightBold">
            {this.apiMenuName('Operativo')}
          </Box>
          , para brindar una mejor experiencia de uso.
        </Typography>
        <FormControlLabel
          label=<FormattedMessage id="infoDialog.index.dontShowAgain" defaultMessage="No volver a mostrar" />
          control={<Checkbox color="secondary" className={`${classes.menu}__tooltip`} onChange={this.dontShowTooltip}></Checkbox>}
        />
      </Fragment>
    );
  };

  handleTooltipClose = () => this.setState({ showOperativoTooltip: false });

  handleChangeLocale = event => {
    const { currentUser, i18nContext, changeLocale } = this.props;
    const newLocale = event.target.value;

    changeLocale(currentUser.client_id, currentUser.id, newLocale);
  };

  render() {
    const { classes, permissions, currentUser, i18nContext } = this.props;
    const { client_id: clientId } = currentUser || {};
    const menuPermissions = permissions
      ? permissions.filter(p => p.permission_action.name === 'View' && p.permission.permission_type === 'menu' && p.can)
      : [];
    const { mobileMoreAnchorEl, showOperativoTooltip } = this.state;
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isAdmin = this.canViewAdmin(currentUser);
    const hasOrders = this.canViewOrders(currentUser);
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}
      >
        {CLIENT_IDS_ALLOWED_FOR_CONTACTS.includes(clientId) && (
          <MenuItem onClick={this.handleMobileMenuClose}>
            <Link to={'/beneficiaries'}>
              <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/beneficiaries')}`}>
                <FormattedMessage id="menu.contacts" />
              </Button>
            </Link>
          </MenuItem>
        )}
        {false && isAdmin && (
          <MenuItem onClick={this.handleMobileMenuClose}>
            <Link to={'/formulary-reports'}>
              <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/formulary-reports')}`}>
                <FormattedMessage id="menu.reports" defaultMessage="Reportes" />
              </Button>
            </Link>
          </MenuItem>
        )}
        {menuPermissions.map(menu => (
          <MenuItem onClick={this.handleMobileMenuClose} key={`mobile-menu-${menu.permission.id}`}>
            <Link to={headerMenu[menu.permission.name].path}>
              <Button
                color="primary"
                className={`${classes.menu}__menu__item ${this.menuSelectedClass(headerMenu[menu.permission.name].path)}`}
              >
                {this.apiMenuName(menu.permission.name)}
              </Button>
            </Link>
          </MenuItem>
        ))}
        {hasOrders && (
          <MenuItem onClick={this.handleMobileMenuClose}>
            <Link to={'/work-orders'}>
              <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/work-orders')}`}>
                <FormattedMessage id="menu.work-orders" defaultMessage="Ordenes de Trabajo" />
              </Button>
            </Link>
          </MenuItem>
        )}
        {isAdmin && (
          <MenuItem onClick={this.handleMobileMenuClose}>
            <Link to={'/admin'}>
              <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/admin')}`}>
                <FormattedMessage id="menu.config" defaultMessage="Configuración" />
              </Button>
            </Link>
          </MenuItem>
        )}
        <MenuItem onClick={this.handleMobileMenuClose}>
          <Button onClick={this.handleLogout} color="primary" className={`${classes.menu}__menu__item`}>
            Logout
          </Button>
        </MenuItem>
      </Menu>
    );

    const renderMenu = (
      <>
        {CLIENT_IDS_ALLOWED_FOR_CONTACTS.includes(clientId) && (
          <Link to={'/beneficiaries'}>
            <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/beneficiaries')}`}>
              <FormattedMessage id="menu.contacts" defaultMessage="Contactos" />
            </Button>
          </Link>
        )}
        {false && isAdmin && (
          <Link to={'/formulary-reports'}>
            <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/formulary-reports')}`}>
              <FormattedMessage id="menu.reports" defaultMessage="Reportes" />
            </Button>
          </Link>
        )}
        {hasOrders && (
          <Link to={'/reports'}>
            <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/reports')}`}>
              <FormattedMessage id="reports.header.title" defaultMessage="Reportes de Formulario" />
            </Button>
          </Link>
        )}
        {menuPermissions.map(menu => (
          <ClickAwayListener onClickAway={this.handleTooltipClose} key={`menu-${menu.permission.id}`}>
            <Tooltip
              title={this.renderTooltip()}
              arrow
              interactive
              open={false && showOperativoTooltip && menu.permission.name === 'Operativo'}
              classes={{ tooltip: classes.tooltip }}
            >
              <Link to={headerMenu[menu.permission.name].path}>
                <Button
                  color="primary"
                  className={`${classes.menu}__menu__item ${this.menuSelectedClass(headerMenu[menu.permission.name].path)}`}
                >
                  <FormattedMessage
                    id={this.apiMenuNameKey(menu.permission.name)}
                    defaultMessage={this.apiMenuName(menu.permission.name)}
                  />
                </Button>
              </Link>
            </Tooltip>
          </ClickAwayListener>
        ))}
        <Link to={'/inspections'}>
          <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/inspections')}`}>
            <FormattedMessage id="project.inspection.inspections" defaultMessage="Inspecciones" />
          </Button>
        </Link>
        {hasOrders && (
          <Link to={'/work-orders'}>
            <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/work-orders')}`}>
              <FormattedMessage id="menu.work-orders" defaultMessage="Ordenes de Trabajo" />
            </Button>
          </Link>
        )}

        {isAdmin && (
          <Link to={'/admin'}>
            <Button color="primary" className={`${classes.menu}__menu__item ${this.menuSelectedClass('/admin')}`}>
              <FormattedMessage id="menu.config" defaultMessage="Configuración" />
            </Button>
          </Link>
        )}
      </>
    );

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.root}>
          <Toolbar className={`${classes.root}__toolbar`}>
            {!!currentUser.client_logo && <img className={classes.client_logo} src={currentUser.client_logo} alt="Logo" />}
            <img src={capLogo} alt="Logo" className={classes.cap_logo} />
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {renderMenu}

              <div className={`${classes.menu}__menu__action`}>
                <InputLabel>
                  <FormattedMessage id="language" defaultMessage="Idioma" />:
                </InputLabel>
                <Select className={`${classes.menu}__menu__action_select`} value={i18nContext.locale} onChange={this.handleChangeLocale}>
                  <MenuItem value="es">Español</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                </Select>
              </div>
              <Button onClick={this.handleLogout} color="primary" className={`${classes.menu}__menu__action`}>
                <FormattedMessage id="logout" defaultMessage="Salir" />
              </Button>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="primary">
                <MenuIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

// Redux connection
export default connect(
  state => ({
    currentUser: getCurrentUser(state),
    isLoggedIn: checkAuthState(state),
    permissions: getPermissions(state)
  }),
  {
    logout: actionCreators.logout,
    changeLocale: authActions.changeLocale
  }
)(withI18NContext(withStyles(styles)(Header)));
