import React, { useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Box, Popover, TextField, InputAdornment, MenuItem, MenuList } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { SIMPLE_DATE_FORMAT } from '../../utils/utils';

const endOfDays = moment().add(1000, 'y');
const DEFAULT_RANGE = Object.freeze({
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month'
});

export const DateRangePicker = ({ onChange = () => {} }) => {
  const { messages: intlMessages } = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const open = Boolean(anchorEl);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleStartDateChange = date => {
    handleChange({ start: date });
  };

  const handleEndDateChange = date => {
    handleChange({ end: date });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = ({ start = startDate, end = endDate }) => {
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      onChange({ startDate: start, endDate: end });
      handleClose();
    }
  };

  const handleDefaultRangeChange = rangeType => {
    switch (rangeType) {
      case DEFAULT_RANGE.TODAY:
        handleChange({ start: moment(), end: moment() });
        break;
      case DEFAULT_RANGE.YESTERDAY:
        handleChange({ start: moment().subtract(1, 'days'), end: moment().subtract(1, 'days') });
        break;
      case DEFAULT_RANGE.LAST_7_DAYS:
        handleChange({ start: moment().subtract(6, 'days'), end: moment() });
        break;
      case DEFAULT_RANGE.LAST_30_DAYS:
        handleChange({ start: moment().subtract(29, 'days'), end: moment() });
        break;
      case DEFAULT_RANGE.THIS_MONTH:
        handleChange({ start: moment().startOf('month'), end: moment().endOf('month') });
        break;
      case DEFAULT_RANGE.LAST_MONTH:
        handleChange({
          start: moment()
            .subtract(1, 'month')
            .startOf('month'),
          end: moment()
            .subtract(1, 'month')
            .endOf('month')
        });
        break;
      default:
        break;
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <TextField
        fullWidth
        onClick={handleOpen}
        value={
          startDate && endDate ? `${moment(startDate).format(SIMPLE_DATE_FORMAT)} - ${moment(endDate).format(SIMPLE_DATE_FORMAT)}` : ''
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarTodayIcon color="action" />
            </InputAdornment>
          ),
          readOnly: true
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box p={2} display="flex" flexWrap={'nowrap'} style={{ width: 'fit-content' }}>
          <Box>
            <MenuList>
              <MenuItem onClick={() => handleDefaultRangeChange(DEFAULT_RANGE.TODAY)}>
                <FormattedMessage id="datepicker.today" />
              </MenuItem>
              <MenuItem onClick={() => handleDefaultRangeChange(DEFAULT_RANGE.YESTERDAY)}>
                <FormattedMessage id="datepicker.yesterday" />
              </MenuItem>
              <MenuItem onClick={() => handleDefaultRangeChange(DEFAULT_RANGE.LAST_7_DAYS)}>
                <FormattedMessage id="datepicker.last_7_days" />
              </MenuItem>
              <MenuItem onClick={() => handleDefaultRangeChange(DEFAULT_RANGE.LAST_30_DAYS)}>
                <FormattedMessage id="datepicker.last_30_days" />
              </MenuItem>
              <MenuItem onClick={() => handleDefaultRangeChange(DEFAULT_RANGE.THIS_MONTH)}>
                <FormattedMessage id="datepicker.this_month" />
              </MenuItem>
              <MenuItem onClick={() => handleDefaultRangeChange(DEFAULT_RANGE.LAST_MONTH)}>
                <FormattedMessage id="datepicker.last_month" />
              </MenuItem>
            </MenuList>
          </Box>
          <KeyboardDatePicker
            disableToolbar
            variant="static"
            label={intlMessages['contract.start_date']}
            value={startDate}
            onChange={handleStartDateChange}
            format={SIMPLE_DATE_FORMAT}
            emptyLabel={SIMPLE_DATE_FORMAT}
            style={{ marginRight: '16px' }}
            autoOk
          />
          <KeyboardDatePicker
            disableToolbar
            variant="static"
            label={intlMessages['contract.end_date']}
            value={endDate}
            onChange={handleEndDateChange}
            format={SIMPLE_DATE_FORMAT}
            emptyLabel={SIMPLE_DATE_FORMAT}
            minDate={startDate || endOfDays}
            autoOk
          />
        </Box>
      </Popover>
    </MuiPickersUtilsProvider>
  );
};

DateRangePicker.propTypes = {
  onChange: PropTypes.func
};
