export const columnDefs = [
  { headerName: 'Id', field: 'id', filter: 'agTextColumnFilter' },
  {
    headerName: 'Nombre',
    field: 'name',
    resizable: true,
    // width: 600,
    filter: 'agTextColumnFilter',
    filterParams: { defaultOption: 'contains' }
  },
  { headerName: 'Provincia', field: 'provincia', filter: 'agTextColumnFilter' },
  { headerName: 'Cantón', field: 'canton', filter: 'agTextColumnFilter' },
  { headerName: 'Distrito', field: 'distrito', filter: 'agTextColumnFilter' },
  { headerName: 'Antigüedad', field: 'antiguedad', filter: 'agTextColumnFilter' },
  { headerName: 'Propiedad', field: 'propiedad', filter: 'agTextColumnFilter' },
  { headerName: 'Horas diarias', field: 'horas_diarias', filter: 'agTextColumnFilter' },
  { headerName: 'Datáfonos', field: 'datafonos', valueFormatter: booleanFormatter },
  { headerName: 'Formato', field: 'formato', filter: 'agTextColumnFilter' },
  { headerName: 'Desafíos', field: 'desafios', filter: 'agTextColumnFilter' }
];

export const headerMenu = {
  Analítica: { name: 'Analítica', path: '/analytic' },
  Ejecutivo: { name: 'Ejecutivo', path: '/executive' },
  Operativo: { name: 'Operativo', path: '/projects' },
  Administración: { name: 'Administración', path: '/admin' }
};

export const inspectionStatuses = {
  initial: { name: 'En borrador', nextSteps: ['under_review'] },
  under_review: { name: 'En revisión', nextSteps: ['published'] },
  published: { name: 'Publicada', nextSteps: ['initial', 'under_review'] },
  approved: { name: 'Aprobada', nextSteps: ['published'] },
  unpublished: { name: 'Despublicada', nextSteps: ['published'] }
};

export const booleanFormatter = params => (params.value ? 'si' : 'no');

export const dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

export const userTypes = {
  admin: 'Administrador',
  supervisor: 'Supervisor',
  inspector: 'Inspector',
  facilitator: 'Facilitador',
  visualizator: 'Visualizador'
};

export const validRoutesPaths = ['/work-orders', '/projects', '/reports', '/projects-info', '/beneficiaries', '/contacts', '/inspections'];

/* TODO: This is temporary we have to finish the Beneficiaries module in order to unlock it for all Clients for now is for "Ayudas en Accion And BID" */
export const CLIENT_IDS_ALLOWED_FOR_CONTACTS = [17, 1];
export const MAX_UPLOAD_FILE_SIZE = 30000000;
