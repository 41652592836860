import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';
import moment from 'moment';
import { SIMPLE_DATE_FORMAT } from '../utils/utils';

const isValidDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) return false;
  const start = moment(startDate, SIMPLE_DATE_FORMAT, true);
  const end = moment(endDate, SIMPLE_DATE_FORMAT, true);
  return start.isValid() && end.isValid() && start.isSameOrBefore(end);
};

export const useUpdateInspection = config =>
  useMutation(async ({ inspection, id }) => {
    const axiosResult = await getApi().put(`/inspections/${id}`, inspection);
    return axiosResult.data;
  }, config);

export const useGetInspections = ({ clientId, params = {}, dateRange = {} }) => {
  const queryKey = ['inspections', { clientId, params, dateRange }];

  const queryFn = async () => {
    const apiParams = {
      ...params,
      ...(isValidDateRange(dateRange.startDate, dateRange.endDate)
        ? {
            'by_inspected_at[start_date]': moment(dateRange.startDate).format(SIMPLE_DATE_FORMAT),
            'by_inspected_at[end_date]': moment(dateRange.endDate).format(SIMPLE_DATE_FORMAT)
          }
        : {})
    };

    const response = await getApi().get(`/clients/${clientId}/inspections`, {
      params: apiParams
    });

    const transformedData = transformSnake2Camel(response.data);
    return transformedData;
  };

  return useQuery(queryKey, queryFn);
};
