import {
  alpha,
  Chip,
  Grid,
  makeStyles,
  MuiThemeProvider,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import React from 'react';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2.js';
import { HeaderContent } from '../../components/molecules/HeaderContent.jsx';
import { BodyContent } from '../../components/molecules/BodyContent.jsx';
import { WideContainer } from '../../components/atoms/WideContainer.jsx';
import { HeadCell } from '../../components/molecules/Table/HeadCell.jsx';
import { palette } from '../../settings/themes/colors.js';
import { BasicTableCell } from '../../components/molecules/Table/BasicTableCell.jsx';
import { useGetInspections } from '../../queries/queryInspection.js';
import PropTypes from 'prop-types';
import { getCurrentUser } from '../../utils/auth.js';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { parseStringDateToFormat } from '../../utils/utils.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateRangePicker } from '../../components/molecules/DateRangePicker.jsx';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    '& tr ': {
      borderBottom: `1px solid ${palette.darkGrayTable}`
    }
  },
  dataCell: {
    backgroundColor: palette.white
  },
  chip: {
    borderRadius: 8,
    border: `1px solid ${alpha(palette.dimGray, 0.2)}`,
    color: `${palette.white} !important`,
    backgroundColor: palette.captuDataYellow,
    fontWeight: 'bold'
  },
  link: {
    color: palette.captudataBlue,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  containerFilter: {
    gap: '10px',
    display: 'flex',
    padding: '0px 20px 10px 20px',
    alignItems: 'center'
  }
});

const STATUS_CHIP_COLORS = {
  'project.inspection.status.published': palette.captuDataYellow,
  'project.inspection.status.initial': palette.dimGray
};

const TYPE_CHIP_COLORS = {
  'project.inspections.type.byRoute': palette.captudataBlue,
  'project.inspections.type.byForm': palette.captudataBlue50
};
export const INSPECTION_TYPES = Object.freeze({
  ROUTE: 'route',
  FORMULARY: 'formulary'
});

const getTranslatedKeys = (inspection, intlMessages) => {
  const isFormulary = inspection.inspectionType === INSPECTION_TYPES.FORMULARY;
  const typeKey = isFormulary ? 'project.inspections.type.byForm' : 'project.inspections.type.byRoute';
  const translationTypeKey = intlMessages[typeKey];
  const statusKey = `project.inspection.status.${inspection.status}`;
  const translationStatusKey = intlMessages[statusKey];

  return { typeKey, translationTypeKey, statusKey, translationStatusKey };
};

export const InspectionsComponent = ({ clientId }) => {
  const { messages: intlMessages } = useIntl();
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(25);
  const [dateRange, setDateRange] = React.useState({
    startDate: null,
    endDate: null
  });
  const { data: { inspections = [], meta = {} } = {} } = useGetInspections({
    clientId,
    params: { page: page + 1, per_page: pageSize },
    dateRange
  });
  const getChipStatusColor = statusKey => STATUS_CHIP_COLORS[statusKey] || palette.captuDataYellow;
  const getChipTypeColor = typeKey => TYPE_CHIP_COLORS[typeKey] || palette.captudataBlue;
  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = event => {
    const newPageSize = parseInt(event.target.value, 10);

    setPageSize(newPageSize);
    setPage(0);
  };
  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} ${intlMessages['table.of']} ${count !== -1 ? count : `more than ${to}`}`;

  const classes = useStyles();

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent title={intlMessages['project.inspection.inspections']} />
      <Grid container spacing={2}>
        <Grid item>
          <div className={classes.containerFilter}>
            <Typography variant="body2">{intlMessages['projects.filter.dateRange']}</Typography>
            <DateRangePicker onChange={range => setDateRange(range)} />
          </div>
        </Grid>
      </Grid>
      <BodyContent component={WideContainer}>
        <div className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <HeadCell>
                  <FormattedMessage id="common.number" />
                </HeadCell>
                <HeadCell>
                  <FormattedMessage id="common.date" />
                </HeadCell>
                <HeadCell>
                  <FormattedMessage id="group.project" />
                </HeadCell>
                <HeadCell>
                  <FormattedMessage id="group.type" />
                </HeadCell>
                <HeadCell>
                  <FormattedMessage id="project.inspections.type.name.form" />
                </HeadCell>
                <HeadCell>
                  <FormattedMessage id="project.inspections.inspector" />
                </HeadCell>
                <HeadCell>
                  <FormattedMessage id="common.status" />
                </HeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inspections.map(inspection => {
                const { typeKey, translationTypeKey, statusKey, translationStatusKey } = getTranslatedKeys(inspection, intlMessages);

                return (
                  <TableRow key={inspection.id} className={classes.dataCell}>
                    <BasicTableCell>
                      <Link
                        className={classes.link}
                        to={
                          inspection.inspectionType === INSPECTION_TYPES.FORMULARY
                            ? `/operative/projects/${inspection.project.id}/inspectionspunctual/${inspection.id}`
                            : `/operative/projects/${inspection.project.id}/inspectionslineal/${inspection.id}`
                        }
                      >
                        <Typography>{`#${inspection.id}`}</Typography>
                      </Link>
                    </BasicTableCell>
                    <BasicTableCell>
                      <Typography style={{ minWidth: '86px' }}>{parseStringDateToFormat(inspection.inspectedAt)}</Typography>
                    </BasicTableCell>
                    <BasicTableCell>
                      <Typography>{(inspection.project || {}).name || '-'}</Typography>
                    </BasicTableCell>
                    <BasicTableCell>
                      <Chip label={translationTypeKey} className={classes.chip} style={{ backgroundColor: getChipTypeColor(typeKey) }} />
                    </BasicTableCell>
                    <BasicTableCell>
                      <Typography>{inspection.formularyName}</Typography>
                    </BasicTableCell>
                    <BasicTableCell>
                      <Typography style={{ minWidth: '200px' }}>{inspection.inspector.fullName}</Typography>
                    </BasicTableCell>
                    <BasicTableCell>
                      <Chip
                        label={translationStatusKey}
                        className={classes.chip}
                        style={{ backgroundColor: getChipStatusColor(statusKey) }}
                      />
                    </BasicTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div className={classes.dataCell}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              className={classes.dataCell}
              component="div"
              rowsPerPage={pageSize}
              count={meta.totalCount || 0}
              onPageChange={onChangePage}
              labelRowsPerPage={intlMessages['table.rowsPerPage.inspector']}
              labelDisplayedRows={labelDisplayedRows}
              onRowsPerPageChange={onChangeRowsPerPage}
              page={page}
            />
          </div>
        </div>
      </BodyContent>
    </MuiThemeProvider>
  );
};

InspectionsComponent.propTypes = {
  clientId: PropTypes.number
};
const mapToProps = state => ({ clientId: getCurrentUser(state).client_id });

export const Inspections = connect(mapToProps)(withRouter(InspectionsComponent));
